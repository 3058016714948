const stickyElementModule = () => import(/* webpackChunkName: 'utils/stickyElement' */'../../utils/js/stickyElement');

$(() => {
  initPseudoTabs();
  initStickyTabs();

  // cookieManagement.eraseCookie('marketLanguageChoice');
  // Permet d'effacer les cookies pour éviter les redirections
  // A supprimer lors de la mise en prod

  $('.cookiesButton').on('click', (event) => {
    event.preventDefault();

    const url = event.currentTarget.href;
    const target = event.currentTarget.target;
    const valueMarket = url.split('/')[3];

    cookieManagement.createCookie('marketLanguageChoice', valueMarket, 365);

    if (target === '_blank') {
      window.open(url, target);
    } else {
      window.location = url;
    }
  });
});

let europeSliderInitiated = false,
  UKSliderInitiated = false;

const initPseudoTabs = () => {
  $('.js-pseudoTabs').on('click', (event) => {
    event.preventDefault();

    const area = $(event.currentTarget).data('selector');

    if (area === 'UK') {
      $('body').addClass('discoverUK').removeClass('discoverEurope');
    } else {
      $('body').addClass('discoverEurope').removeClass('discoverUK');
    }

    genericScrollTo.go('.js-pseudoTabs');
  });
};

const initStickyTabs = () => {
  stickyElementModule().then(({stickyElement}) => {
    //handling sticky animation
    stickyElement.init({
      selector: 'body',
      fixedStart: () => {
        const $stickyTabs = $('.js-stickyTabs');
        return $stickyTabs.length ? $stickyTabs.offset().top - 10 : 0;
      },
      fixedStartSelector: '.js-stickyTabs',
      classSticky: 'stickyTabs',
      deviceList: 'mobile',
      nameSpace: 'stickyTabs'
    });
  });
};
